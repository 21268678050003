// Atoms
import { ClipboardDocumentListIcon, CogIcon, HomeIcon, NewspaperIcon, PhotoIcon, Squares2X2Icon, SquaresPlusIcon, LanguageIcon } from "@heroicons/react/24/outline";
import IconPlus from "app/components/atoms/icons/iconPlus";
import Indicator from "app/components/atoms/indicator";
import { getLocale, i18n } from "app/i18n";
import { useCreateBlockMutation, useCreateBlockTranslationMutation } from "app/stores/project";
import { showServerError, showServerSuccess } from "app/utils/server";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import ModalBlock from "../../modals/modalBlock";
import LogoSvalla from "app/components/atoms/logoSvalla";
import { cleanStructure } from "app/utils/content";
import Permission from "../../permission";
import { hasPermissionProject } from "app/utils/roles";

const tabs = [
  { name: "label.home", href: "", icon: HomeIcon, end: true },
  { name: "label.pages", href: "pages", icon: Squares2X2Icon, hasSubmenu: true, end: false },
  { name: "label.blocks", href: "blocks", icon: SquaresPlusIcon, hasSubmenu: true, end: false },
  { name: "label.forms", href: "forms", icon: ClipboardDocumentListIcon, end: false, notify: false },
  { name: "label.newsletter", href: "newsletter", permission: { min: "EDITOR" }, icon: NewspaperIcon, end: false },
  { name: "label.translations", href: "translations", permission: { min: "EDITOR" }, icon: LanguageIcon, end: false },
  { name: "label.media", href: "media", icon: PhotoIcon, end: false },
  { name: "label.settings", href: "settings", permission: { min: "EDITOR" }, icon: CogIcon, end: false },
];

// É preciso passar isto para um Atom e os estilos têm que ser dinâmicos
function CustomLink({ item }) {
  const { name, end, hasSubmenu, href, notify } = item;
  let resolved = useResolvedPath(href);
  let match = useMatch({ path: resolved.pathname, end });

  let styles = {
    button: "group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium main-transition ",
    text: "mt-2 main-transition ",
    icon: "h-6 w-6 main-transition ",
  };

  // BUTTON
  if (match) styles.button += "bg-[#0056FF] text-white";
  else styles.button += "text-indigo-100 hover:bg-gray-700 hover:text-white";

  // TEXT
  if (match) styles.text += "text-white";
  else styles.text += "text-gray-700 group-hover:text-white";

  // TEXT
  if (match) styles.icon += "text-white";
  else styles.icon += "text-gray-600 group-hover:text-white";

  if (hasSubmenu)
    return (
      <span className={styles.button}>
        <item.icon className={styles.icon} aria-hidden="true" />
        <Indicator active={notify} noShow className="absolute ml-1 left-1/2" />
        <span className={styles.text}>{i18n(name)}</span>
      </span>
    );
  return (
    <Link to={href} className={styles.button}>
      <item.icon className={styles.icon} aria-hidden="true" />
      <Indicator active={notify} noShow className="absolute ml-1 left-1/2" />
      <span className={styles.text}>{i18n(name)}</span>
    </Link>
  );
}

export default function ProjectNavbar(props) {
  const { postTypes, contentTypes, blocks } = props;

  // PARAMS
  const locale = getLocale();
  const location = useLocation();
  const navigate = useNavigate();

  // STORE
  const { user } = useSelector((state) => state.auth);
  const { project, language } = useSelector((state) => state.project);

  // STATE
  const [menu, setMenu] = useState(tabs);
  const [isHover, setIsHover] = useState(false);
  const [modalBlock, setModalBlock] = useState(null);

  // REQUEST
  const [createBlock, { isLoading }] = useCreateBlockMutation();
  const [createBlockTranslation, { isLoading: isCreating }] = useCreateBlockTranslationMutation();

  // OPEN AND CLOSE LISTS
  useEffect(() => {
    setIsHover(false);
  }, [location]);

  // FILL MENU WITH PROJECT DATA
  useEffect(() => {
    if (project) {
      let temp = cleanStructure(tabs);
      // FORMS
      if (!project?.modules?.forms) {
        const index = temp.findIndex((e) => e.href === "forms");
        if (index !== -1) temp.splice(index, 1);
      } else {
        const index = temp.findIndex((e) => e.href === "forms");
        const unread = project.forms.find((e) => e.unReadCount);
        if (unread && index !== -1) temp[index].notify = true;
      }
      // NEWSLETTER
      if (!project?.modules?.newsletter) {
        const index = temp.findIndex((e) => e.href === "newsletter");
        if (index !== -1) temp.splice(index, 1);
      }
      // TRANSLATIONS
      if (!project?.modules?.translations) {
        const index = temp.findIndex((e) => e.href === "translations");
        if (index !== -1) temp.splice(index, 1);
      }
      // HOME PAGE
      if (!project?.settings?.frontend?.home) {
        const index = temp.findIndex((e) => e.href === "settings");
        if (index !== -1) temp[index].notify = true;
      }

      setMenu([...temp]);
    }
  }, [project, locale]);

  // ON CREATE BLOCK
  const onCreateBlock = (block) => {
    setModalBlock({
      isOpen: true,
      contentTypes,
      translations: block?.translations,
      onClose: () => setModalBlock(null),
      onSubmit: (data) => {
        if (block) {
          createBlockTranslation({
            block: block.id,
            language: language.id,
            title: data.title,
            slug: data.key,
            copy: !!data?.copy,
            origin: data?.origin?.value,
            translate: !!data?.translate,
          }).then((res) => {
            if (res && res.data) {
              navigate(`blocks/${res.data.block.id}/${res.data.language.slug}`);
              showServerSuccess(i18n("toast.success_block_create"));
              setModalBlock(null);
            } else {
              showServerError(res);
            }
          });
        } else {
          createBlock({ ...data, language: language.id, project: project.id }).then((res) => {
            if (res && res.data) {
              navigate(`blocks/${res.data.id}/${res.data.content.language.slug}`);
              showServerSuccess(i18n("toast.success_block_create"));
              setModalBlock(null);
            } else {
              showServerError(res);
            }
          });
        }
      },
    });
  };

  return (
    <>
      <div className="relative bg-slate-700 w-28" onMouseLeave={() => setIsHover(false)}>
        <div className={`absolute inset-y-0 right-0 z-20 bg-slate-100 border-r p-8 w-96 main-transition ${isHover ? "translate-x-full" : "translate-x-0"}`}>
          {/* ===
          === MENU PAGES
          === */}
          {isHover && isHover === "pages" && (
            <div onMouseMove={() => setIsHover("pages")}>
              <h1 className="font-bold tracking-tighter text-xl uppercase">{i18n("label.pages")}</h1>
              {postTypes?.length ? (
                <ul>
                  {postTypes.map((item, key) => (
                    <li key={key} className="mt-8">
                      {/* POST TYPE HEADER */}
                      {item.key !== "pages" && (
                        <Link to={`posts/${item.key}`}>
                          <h4 className="flex items-end justify-between border-b border-slate-300">
                            <span className="font-semibold pr-2 text-slate-800">
                              <span>{item.title} </span>
                              {item.count ? <span className="text-xs bg-slate-300 aspect-square rounded-full px-1 align-middle text-white font-normal">{item.count}</span> : ""}
                            </span>
                            <span className="text-xs text-slate-400 hover:text-slate-800">{i18n("button.view_all")}</span>
                          </h4>
                        </Link>
                      )}
                      {/* LIST POSTS */}
                      {!!item?.posts?.length && (
                        <nav className="flex flex-col space-y-1 mt-2" aria-label="pages">
                          {item.posts
                            .filter((post) => post?.content?.language)
                            .map((post) => (
                              <Link to={`posts/${item.key}/${post.id}/${post.content.language.slug}`} key={post.content.slug}>
                                <button
                                  aria-current="page"
                                  className="transition-all duration-300 ease-linear w-full group flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-600 bg-slate-200 hover:bg-white hover:text-gray-900"
                                >
                                  <span className="truncate">{post.content.title}</span>
                                </button>
                              </Link>
                            ))}
                        </nav>
                      )}
                      {/* NO POSTS YET */}
                      {!item.posts?.length && (
                        <button disabled aria-current="page" className="mt-3 w-full flex items-center uppercase justify-center px-3 py-0.5 text-xs font-medium text-gray-300">
                          <span className="truncate">{i18n("label.no_items")}</span>
                        </button>
                      )}
                      {item.key === "pages" && (
                        <Link to={`posts/${item.key}`}>
                          <button
                            aria-current="page"
                            className="transition-all duration-300 ease-linear w-full group flex items-center justify-center px-3 py-2 text-xs font-medium rounded-md text-gray-300 hover:text-gray-900"
                          >
                            <span className="truncate text-center">{item.posts?.length ? i18n("button.view_all") : i18n("label.create_first_page")}</span>
                          </button>
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <Link to={`posts/pages`}>
                  <button
                    aria-current="page"
                    className="transition-all duration-300 ease-linear w-full group flex items-center justify-center px-3 py-2 text-xs font-medium rounded-md text-gray-300 hover:text-gray-900"
                  >
                    <span className="truncate text-center">{i18n("label.create_first_page")}</span>
                  </button>
                </Link>
              )}
            </div>
          )}
          {/* ===
          === MENU BLOCKS
          === */}
          {isHover && isHover === "blocks" && (
            <div className="h-full flex flex-col justify-between" onMouseMove={() => setIsHover("blocks")}>
              <div>
                <h1 className="font-bold tracking-tighter text-xl uppercase">{i18n("label.blocks")}</h1>
                <div className="mt-8">
                  {blocks?.filter((block) => block?.translations?.length)?.length ? (
                    <nav className="flex flex-col space-y-1 mt-2" aria-label="pages">
                      {blocks
                        .filter((block) => block?.translations?.length)
                        .map((block, key) => {
                          const content = block.translations.find((e) => e.language.slug === language.slug);
                          if (content)
                            return (
                              <Link to={`blocks/${block.id}/${content.language.slug}`} key={key}>
                                <button
                                  aria-current="page"
                                  className="transition-all duration-300 ease-linear w-full group flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-600 bg-slate-200 hover:bg-white hover:text-gray-900"
                                >
                                  <span className="truncate">{content.title}</span>
                                </button>
                              </Link>
                            );

                          return (
                            <button
                              key={key}
                              aria-current="page"
                              onClick={() => onCreateBlock(block)}
                              disabled={!hasPermissionProject(user, project, "MANAGER")}
                              className="transition-all duration-300 ease-linear w-full group flex items-center px-3 py-2 text-sm font-medium rounded-md text-gray-600 bg-slate-200 opacity-40 disabled:opacity-30 disabled:pointer-events-none hover:bg-white hover:text-gray-900"
                            >
                              <span className="truncate">
                                {block.translations[0].title}
                                <span className="text-[10px] pl-2">{`[${block.translations[0].language.slug}]`}</span>
                              </span>
                            </button>
                          );
                        })}
                    </nav>
                  ) : (
                    <button disabled aria-current="page" className="mt-3 w-full flex items-center uppercase justify-center px-3 py-0.5 text-xs font-medium text-gray-300">
                      <span className="truncate">{i18n("label.no_items")}</span>
                    </button>
                  )}
                </div>
              </div>
              <div>
                <Permission project dev min="MANAGER">
                  <button className="btn-primary w-full flex items-center justify-center" type="button" onClick={() => onCreateBlock()}>
                    <IconPlus />
                    {i18n("button.create_new_block")}
                  </button>
                </Permission>
              </div>
            </div>
          )}
        </div>
        <div className="relative z-20 bg-white border-r flex w-full h-full flex-col items-center pb-6">
          <div className="flex flex-shrink-0 items-center h-16">
            <LogoSvalla short />
          </div>
          <div className="mt-6 w-full h-full flex-1 space-y-1 px-2 cursor-pointer">
            {menu.map((item, index) => {
              return (
                <Permission key={index} {...item?.permission} project dev>
                  <div onMouseEnter={() => setIsHover(item.hasSubmenu ? item.href : null)}>
                    <CustomLink item={item} />
                  </div>
                </Permission>
              );
            })}
          </div>
        </div>
      </div>

      <ModalBlock {...modalBlock} isLoading={isLoading} />
      {/* Narrow sidebar */}
      {/* <div className="fixed inset-y-0 left-0 z-10 flex-shrink-0 w-64 bg-white border-r-2 border-indigo-100 shadow-lg sm:left-16 sm:w-72 lg:w-64 transform transition-transform duration-300 -translate-x-full">
        fixed element
      </div> */}

      {/* <div className="hidden w-28 bg-indigo-700 overflow-y-auto md:block">
        <div className="relative flex w-full h-full flex-col items-center py-6">
          <div className="flex flex-shrink-0 items-center">
            <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=white" alt="Your Company" />
          </div>
          <div className="mt-6 w-full h-full flex-1 space-y-1 px-2">
            {tabs.map((item, index) => (
              <div key={index}>
                <CustomLink to={`${item.href}`} hasMenu={item.hasSubmenu}>
                  <item.icon className={classNames(item.current ? "text-white" : "text-indigo-300 group-hover:text-white", "h-6 w-6")} aria-hidden="true" />
                  <span className="mt-2">{item.name}</span>
                </CustomLink>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      {/* Mobile menu */}
      {/* <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as="div" className="relative z-20 md:hidden" onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-indigo-700 pt-5 pb-4">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-1 right-0 -mr-14 p-1">
                    <button type="button" className="flex h-12 w-12 items-center justify-center rounded-full focus:outline-none" onClick={() => setMobileMenuOpen(false)}>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      <span className="sr-only">Close sidebar</span>
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex flex-shrink-0 items-center px-4">
                  <img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=white" alt="Your Company" />
                </div>
                <div className="mt-5 h-0 flex-1 overflow-y-auto px-2">
                  <nav className="flex h-full flex-col">
                    <div className="space-y-1">
                      {tabs.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current ? "bg-indigo-800 text-white" : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                            "group py-2 px-3 rounded-md flex items-center text-sm font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          <item.icon className={classNames(item.current ? "text-white" : "text-indigo-300 group-hover:text-white", "mr-3 h-6 w-6")} aria-hidden="true" />
                          <span>{item.name}</span>
                        </a>
                      ))}
                    </div>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
            </div>
          </div>
        </Dialog>
      </Transition.Root> */}
    </>
    // <header className="fixed top-0 left-0 right-0 flex items-center h-20 px-6 bg-white border-b border-b-primary-50 z-50">
    //   <div className="flex-1">
    //     <LogoSvalla />
    //   </div>
    //   <nav className="flex space-x-4" aria-label="Tabs">
    //     {tabs.map((tab) => (
    //       <Permission key={tab.name} {...tab.permission}>
    //         <CustomLink to={tab.href} end={tab.end}>
    //           {tab.name}
    //         </CustomLink>
    //       </Permission>
    //     ))}
    //   </nav>
    //   <div className="flex justify-end flex-1">
    //     <Account />
    //   </div>
    // </header>
  );
}
